import React from "react";
import FeatureLayout from "../components/FeatureLayout/FeatureLayout";
import MainLayout from "../components/MainLayout";

const feature = {
  id: "configurator",
  image: "/images/showcase/feature-ar.png",
  title: "Configurator",
  description: (
    <p>
      Let your customers configure and experience the product of their dreams.
      <br />
      <br />
      Show them exactly what the will get.
    </p>
  ),
  ctaText: "Try it out",
  secondaryImage: "/images/showcase/Pie Circular.png",
  nextRoute: "/augmented-reality",
  width: 55,
};
const Configurator = () => {
  return <FeatureLayout {...feature} />;
};

Configurator.Layout = MainLayout;

export default Configurator;
